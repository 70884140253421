import React, { Fragment, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import c3 from "../../assests/images/payment-secured-icon.svg";
import { FooterSection } from "../LauncherPageComponent/launcherPageComponent";
import logo from "../../assests/images/MINIMIS (1).svg";
import SDK from "../../config";
import "../styles/launcherPageComponent.css";

const ReservationPage = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const email = params.get("email");
  const navigate = useNavigate();

  const clickCountMeIn = () => {
    navigate(`/payment-checkout?email=${encodeURIComponent(email)}`);
  };

  // Check if the current route is '/reservation'
  const isReservationRoute = location.pathname === "/reservation";

  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 829);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 829);
    };

    // Initial check when the component mounts
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Fragment>
      <div style={{ overflow: "hidden" }}>
        <div className="reservation_page">
          <div className="text_part">
            <div className="reservation_logo">
              <div>
                <img
                  src={logo}
                  className="navLogo"
                  style={{
                    paintOrder: "stroke fill",
                    fill: "black",
                    WebkitTextStroke: "12px black",
                  }}
                  alt=""
                />
              </div>
            </div>

            <div className="text_box">
              <p className="pa1">
                Welcome! Get iOS yearly access for ${SDK.FB_PRICE} USD
              </p>
              <p className="pa3">
                That's less than 1/6 the price of a dumbphone, without
                sacrificing the utility of your smartphone or needlessly
                polluting the environment.
              </p>
              <button onClick={clickCountMeIn}>Get access</button>
            </div>
          </div>
          <div
            className={`cards_part ${
              isReservationRoute ? "reservation-specific" : ""
            }`}
          >
            <div className="img_text_box">
              <div className="card-img-text-box">
                <div className="card-img-text-box-background"></div>
                <div className="image_iphone"></div>
              </div>
              <div
                className={`reservation_crad_box ${
                  isReservationRoute ? "reservation_page_box" : ""
                }`}
              >
                {/* <div className="card1">
                  <div className="card_icon">
                    <img src={c1} alt="" />
                  </div>
                  <div className="card_text">
                    <p className="card_p1">
                      Lock in a massive lifetime discount
                    </p>
                    <p className="card_p2">
                      Early bird gets the worm! Prices will increase as we
                      progress, and offer ends when we launch on the app store.
                    </p>
                  </div>
                </div>
                <div className="card1">
                  <div className="card_icon">
                    <img src={c2} alt="" />
                  </div>
                  <div className="card_text">
                    <p className="card_p1">Full refund guarantee</p>
                    <p className="card_p2">
                      Not satisfied? No problem. Email us for a full refund
                      before the app exits early access.
                    </p>
                  </div>
                </div> */}
                <div className="card1 card-bottom">
                  <div className="card_icon">
                    <img src={c3} alt="" />
                  </div>
                  <div className="card_text">
                    <p className="card_p1">Payment secured by Stripe</p>
                    <p className="card_p2">
                      The same payment processor Apple, Amazon, Etsy, Walmart &
                      Target use. No credit card details seen or stored by us!
                    </p>
                  </div>
                </div>
                {/* Move the footer_section here if screen width is less than 830px */}
                {isMobileView && (
                  <div className="footer_section">
                    <FooterSection />
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* Render the footer_section normally if screen width is greater than 830px */}
        </div>
        {!isMobileView && (
          <div className="footer_section">
            <FooterSection />
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default ReservationPage;
