import axios from 'axios';
import toast from 'react-hot-toast';
import handlePaymentSuccess from './handlePaymentSuccess';
import SDK from '../../../config';

export const handleSubmit = async (event, stripe, elements, email, phone, zipcode, CardNumberElement, cardName, setPageLoader, navigate) => {
  event.preventDefault();

  if (!stripe || !elements) {
    return;
  }

  const paymentOf = "iOS"
  const cardNumberElement = elements.getElement(CardNumberElement);

  if (!cardNumberElement) {
    console.error("CardNumberElement not found");
    return;
  }

  setPageLoader(true);

  try {
    const response = await fetch(
      `${SDK.BASE_URL}/api/create-payment-intent`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          phone,
          zipcode,
          paymentOf
        }),
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      console.error("Error creating payment intent:", errorData.error);
      toast.error("Failed to create payment intent.");
      setPageLoader(false);
      return;
    }

    const data = await response.json();
    const clientSecret = data.clientSecret;

    // Confirm Payment
    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: cardNumberElement,
        billing_details: {
          name: cardName,
        },
      },
    });

    if (result.error) {
      console.error("Payment failed:", result.error.message);
      toast.error("Invalid Card Details");
    } else {
      if (result.paymentIntent.status === "succeeded") {
        const methodType = "Debit Card"
        const paymentWay = "iOS"
        await handlePaymentSuccess(
          result.paymentIntent,
          methodType,
          cardName,   
          email,
          phone,
          zipcode,
          paymentWay
        );

        navigate(`/confirmation?email=${encodeURIComponent(email)}`);
        toast.success('Payment Successful');

        // Clear local storage
        localStorage.removeItem('email');
        localStorage.removeItem('phone');
        localStorage.removeItem('zipcode');

      }
    }
  } catch (error) {
    console.error("Error processing payment:", error);
    toast.error("Payment failed. Please try again.");
  } finally {
    setPageLoader(false);
  }

};
