import React from "react";
import "../Components/styles/privacyPolicies.css";

const TermsOfServicesPage = () => {
  return (
    <div className="col-10 offset-1">
      <h5 className="heading">Terms of Services</h5>
      <ol className="orderList1">
        <li className="orderHeading">
          <h5>About the Application</h5>
          <ol type="a" className="orderList2">
            <li className="orderHeading2">
              <p className="paragraph1">
                Welcome to Minimis Launcher (<strong>Application</strong>).
              </p>
            </li>
            <li className="orderHeading2">
              <p className="paragraph1">
                The Application is providing phone usage minimisation services (
                <b>Services</b>). The Application is operated by Minimis
                Technologies Proprietary Limited (ACN663957891). Access to and
                use of the Application, or any of its associated Products or
                Services, is provided by Minimis Technologies Proprietary
                Limited. Please read these terms and conditions (<b>Terms</b>)
                carefully. By using, browsing and/or reading the Application,
                this signifies that you have read, understood and agree to be
                bound by the Terms. If you do not agree with the Terms, you must
                cease usage of the Application, or any of Services, immediately.
              </p>
            </li>
            <li className="orderHeading2">
              <p className="paragraph1">
                Minimis Technologies Proprietary Limited reserves the right to
                review and change any of the Terms by updating this page at its
                sole discretion. When Minimis Technologies Proprietary Limited
                updates the Terms, it will use reasonable endeavours to provide
                you with notice of updates to the Terms. Any changes to the
                Terms take immediate effect from the date of their publication.
                Before you continue, we recommend you keep a copy of the Terms
                for your records.
              </p>
            </li>
          </ol>
        </li>
        <li className="orderHeading">
          <h5>Acceptance of the Terms</h5>
          <ol type="a" className="orderList2">
            <li className="orderHeading2">
              <p className="paragraph1">
                You accept the Terms by remaining on the Application. You may
                also accept the Terms by clicking to accept or agree to the
                Terms where this option is made available to you by Minimis
                Technologies Proprietary Limited in the user interface.
              </p>
            </li>
          </ol>
        </li>
        <li className="orderHeading">
          <h5>Registration to use the Services</h5>
          <ol type="a" className="orderList2">
            <li className="orderHeading2">
              <p className="paragraph1">
                In order to access the Services, you must first register for an
                account through the Application (<b>Account</b>).
              </p>
            </li>
            <li className="orderHeading2">
              <p className="paragraph1">
                As part of the registration process, or as part of your
                continued use of the Services, you may be required to provide
                personal information about yourself (such as identification or
                contact details), including: <br /> (i) Email address <br />
                (ii) Physical address <br /> (iii) Telephone number
              </p>
            </li>
            <li className="orderHeading2">
              <p className="paragraph1">
                You warrant that any information you give to Minimis
                Technologies Proprietary Limited in the course of completing the
                registration process will always be accurate, correct and up to
                date.
              </p>
            </li>
            <li className="orderHeading2">
              <p className="paragraph1">
                Once you have completed the registration process, you will be a
                registered member of the Application (<b>Member</b>) and agree
                to be bound by the Terms.
              </p>
            </li>
            <li className="orderHeading2">
              <p className="paragraph1">
                You may not use the Services and may not accept the Terms if:
                <br />
                (i) you are not of legal age to form a binding contract with
                Minimis Technologies Proprietary Limited; or
                <br />
                (ii) you are a person barred from receiving the Services under
                the laws of Australia or other countries including the country
                in which you are resident or from which you use the Services
              </p>
            </li>
          </ol>
        </li>
        <li className="orderHeading">
          <h5>Your obligations as a Member</h5>
          <ol type="a" className="orderList2">
            <li className="orderHeading2">
              <p className="paragraph1">
                As a Member, you agree to comply with the following:
                <br />
                (i) you will use the Services only for purposes that are
                permitted by:
                <br />
                (A) the Terms; and
                <br />
                (B) any applicable law, regulation or generally accepted
                practices or guidelines in the relevant jurisdictions;
                <br />
                (ii) you have the sole responsibility for protecting the
                confidentiality of your password and/or email address. Use of
                your password by any other person may result in the immediate
                cancellation of the Services;
                <br />
                (iii) any use of your registration information by any other
                person, or third parties, is strictly prohibited. You agree to
                immediately notify Minimis Technologies Proprietary Limited of
                any unauthorised use of your password or email address or any
                breach of security of which you have become aware;
                <br />
                (iv) access and use of the Application is limited,
                non-transferable and allows for the sole use of the Application
                by you for the purposes of Minimis Technologies Proprietary
                Limited providing the Services;
                <br />
                (v) you will not use the Services or the Application in
                connection with any commercial endeavours except those that are
                specifically endorsed or approved by the management of Minimis
                Technologies Proprietary Limited;
                <br />
                (vi) you will not use the Services or Application for any
                illegal and/or unauthorised use which includes collecting email
                addresses of Members by electronic or other means for the
                purpose of sending unsolicited email or unauthorised framing of
                or linking to the Application;
                <br />
                (vii) you agree that commercial advertisements, affiliate links,
                and other forms of solicitation may be removed from the
                Application without notice and may result in termination of the
                Services. Appropriate legal action will be taken by Minimis
                Technologies Proprietary Limited for any illegal or unauthorised
                use of the Application;
                <br />
                (viii) you acknowledge and agree that any automated use of the
                Application or its Services is prohibited.
              </p>
            </li>
          </ol>
        </li>
        <li className="orderHeading">
          <h5>Payment</h5>
          <ol type="a" className="orderList2">
            <li className="orderHeading2">
              <p className="paragraph1">
                All payments made in the course of your use of the Services are
                made using Google Pay, Apple Pay, Stripe or PayPal. In using the
                Application, the Services or when making any payment in relation
                to your use of the Services, you warrant that you have read,
                understood and agree to be bound by the Google Pay, Apple Pay,
                Stripe or PayPal terms and conditions which are available on
                their website.
              </p>
            </li>
          
            <li className="orderHeading2">
              <p className="paragraph1">
                You agree and acknowledge that Minimis Technologies Proprietary
                Limited can vary the Services Fee at any time.
              </p>
            </li>
          </ol>
        </li>
        <li className="orderHeading">
          <h5>Refund Policy</h5>
          <ol type="a" className="orderList2">
            <li className="orderHeading2">
              <p className="paragraph1">
                Not satisfied? No problem. Email us for a full refund before the app exits early access.
              </p>
            </li>
          </ol>
        </li>
        <li className="orderHeading">
          <h5>Copyright and Intellectual Property</h5>
          <ol type="a" className="orderList2">
            <li className="orderHeading2">
              <p className="paragraph1">
                The Application, the Services and all of the related products of
                Minimis Technologies Proprietary Limited are subject to
                copyright. The material on the Application is protected by
                copyright under the laws of Australia and through international
                treaties. Unless otherwise indicated, all rights (including
                copyright) in the Services and compilation of the Application
                (including but not limited to text, graphics, logos, button
                icons, video images, audio clips, Application code, scripts,
                design elements and interactive features) or the Services are
                owned or controlled for these purposes, and are reserved by
                Minimis Technologies Proprietary Limited or its contributors.
              </p>
            </li>
            <li className="orderHeading2">
              <p className="paragraph1">
                All trademarks, service marks and trade names are owned,
                registered and/or licensed by Minimis Technologies Proprietary
                Limited, who grants to you a worldwide, non-exclusive,
                royalty-free, revocable license whilst you are a Member to:
                <br />
                (i) use the Application pursuant to the Terms;
                <br />
                (ii) copy and store the Application and the material contained
                in the Application in your devices cache memory;
                <br />
                (iii) print pages from the Application for your own personal and
                non-commercial use. Minimis Technologies Proprietary Limited
                does not grant you any other rights whatsoever in relation to
                the Application or the Services. All other rights are expressly
                reserved by Minimis Technologies Proprietary Limited.
              </p>
            </li>
            <li className="orderHeading2">
              <p className="paragraph1">
                Minimis Technologies Proprietary Limited retains all rights,
                title and interest in and to the Application and all related
                Services. Nothing you do on or in relation to the Application
                will transfer any:
                <br />
                (i) business name, trading name, domain name, trade mark,
                industrial design, patent, registered design or copyright, or
                <br />
                (ii) a right to use or exploit a business name, trading name,
                domain name, trade mark or industrial design, or
                <br />
                (iii) a thing, system or process that is the subject of a
                patent, registered design or copyright (or an adaptation or
                modification of such a thing, system or process), to you.
              </p>
            </li>
            <li className="orderHeading2">
              <p className="paragraph1">
                You may not, without the prior written permission of Minimis
                Technologies Proprietary Limited and the permission of any other
                relevant rights owners: broadcast, republish, upload to a third
                party, transmit, post, distribute, show or play in public, adapt
                or change in any way the Services or third party Services for
                any purpose, unless otherwise provided by these Terms. This
                prohibition does not extend to materials on the Application,
                which are freely available for re-use or are in the public
                domain.
              </p>
            </li>
          </ol>
        </li>
        <li className="orderHeading">
          <h5>Privacy</h5>
          <p className="paragraph2">
            Minimis Technologies Proprietary Limited takes your privacy
            seriously and any information provided through your use of the
            Application and/or Services are subject to Minimis Technologies
            Proprietary Limited Privacy Policy, which is available
          </p>
        </li>
        <li className="orderHeading">
          <h5>General Disclaimer</h5>
          <ol type="a" className="orderList2">
            <li className="orderHeading2">
              <p className="paragraph1">
                Nothing in the Terms limits or excludes any guarantees,
                warranties, representations or conditions implied or imposed by
                law, including the Australian Consumer Law (or any liability
                under them) which by law may not be limited or excluded.
              </p>
            </li>
            <li className="orderHeading2">
              <p className="paragraph1">
                Subject to this clause, and to the extent permitted by law: all
                terms, guarantees, warranties, representations or conditions
                which are not expressly stated in the Terms are excluded; and
                Minimis Technologies Proprietary Limited will not be liable for
                any special, indirect or consequential loss or damage (unless
                such loss or damage is reasonably foreseeable resulting from our
                failure to meet an applicable Consumer Guarantee), loss of
                profit or opportunity, or damage to goodwill arising out of or
                in connection with the Services or these Terms (including as a
                result of not being able to use the Services or the late supply
                of the Services), whether at common law, under contract, tort
                (including negligence), in equity, pursuant to statute or
                otherwise.
              </p>
            </li>
            <li className="orderHeading2">
              <p className="paragraph1">
                Use of the Application and the Services is at your own risk.
                Everything on the Application and the Services is provided to
                you "as is" and "as available" without warranty or condition of
                any kind. None of the affiliates, directors, officers,
                employees, agents, contributors and licensors of Minimis
                Technologies Proprietary Limited make any express or implied
                representation or warranty about the Services or any products or
                Services (including the products or Services of Minimis
                Technologies Proprietary Limited) referred to on the
                Application. This includes (but is not restricted to) loss or
                damage you might suffer as a result of any of the following:
                failure of performance, error, omission, interruption, deletion,
                defect, failure to correct defects, delay in operation or
                transmission, computer virus or other harmful component, loss of
                data, communication line failure, unlawful third party conduct,
                or theft, destruction, alteration or unauthorised access to
                records; the accuracy, suitability or currency of any
                information on the Application, the Services, or any of its
                Services related products (including third party material and
                advertisements on the Application); costs incurred as a result
                of you using the Application, the Services or any of the
                products of Minimis Technologies Proprietary Limited; and the
                Services or operation in respect to links which are provided for
                your convenience.
              </p>
            </li>
          </ol>
        </li>
        <li className="orderHeading">
          <h5>Limitation of liability</h5>
          <ol type="a" className="orderList2">
            <li className="orderHeading2">
              <p className="paragraph1">
                Minimis Technologies Proprietary Limiteds total liability
                arising out of or in connection with the Services or these
                Terms, however arising, including under contract, tort
                (including negligence), in equity, under statute or otherwise,
                will not exceed the resupply of the Services to you.
              </p>
            </li>
            <li className="orderHeading2">
              <p className="paragraph1">
                You expressly understand and agree that Minimis Technologies
                Proprietary Limited, its affiliates, employees, agents,
                contributors and licensors shall not be liable to you for any
                direct, indirect, incidental, special consequential or exemplary
                damages which may be incurred by you, however caused and under
                any theory of liability. This shall include, but is not limited
                to, any loss of profit (whether incurred directly or
                indirectly), any loss of goodwill or business reputation and any
                other intangible loss
              </p>
            </li>
            {/* <li className="orderHeading2">
              <p className="paragraph1">
                Nothing in the Terms limits or excludes any guarantees,
                warranties, representations or conditions implied or imposed by
                law, including the Australian Consumer Law (or any liability
                under them) which by law may not be limited or excluded.
              </p>
            </li>
            <li className="orderHeading2">
              <p className="paragraph1">
                Nothing in the Terms limits or excludes any guarantees,
                warranties, representations or conditions implied or imposed by
                law, including the Australian Consumer Law (or any liability
                under them) which by law may not be limited or excluded.
              </p>
            </li> */}
          </ol>
        </li>
        <li className="orderHeading">
          <h5>Competitors</h5>
          <p className="paragraph2">
            If you are in the business of providing similar Services for the
            purpose of providing them to users for a commercial gain, whether
            business users or domestic users, then you are a competitor of
            Minimis Technologies Proprietary Limited. Competitors are not
            permitted to use or access any information or content on our
            Application. If you breach this provision, Minimis Technologies
            Proprietary Limited will hold you fully responsible for any loss
            that we may sustain and hold you accountable for all profits that
            you might make from such a breach.
          </p>
        </li>
        <li className="orderHeading">
          <h5>Termination of Contract</h5>
          <ol type="a" className="orderList2">
            <li className="orderHeading2">
              <p className="paragraph1">
                The Terms will continue to apply until terminated by either you
                or by Minimis Technologies Proprietary Limited as set out below.
              </p>
            </li>
            <li className="orderHeading2">
              <p className="paragraph1">
                If you want to terminate the Terms, you may do so by ceasing to
                use the app or not renewing the Subscription.
              </p>
            </li>
            <li className="orderHeading2">
              <p className="paragraph1">
                Minimis Technologies Proprietary Limited may at any time,
                terminate the Terms with you if: you have breached any provision
                of the Terms or intend to breach any provision; Minimis
                Technologies Proprietary Limited is required to do so by law;
                the provision of the Services to you by Minimis Technologies
                Proprietary Limited is, in the opinion of Minimis Technologies
                Proprietary Limited, no longer commercially viable.
              </p>
            </li>
            <li className="orderHeading2">
              <p className="paragraph1">
                Subject to local applicable laws, Minimis Technologies
                Proprietary Limited reserves the right to discontinue or cancel
                your membership at any time and may suspend or deny, in its sole
                discretion, your access to all or any portion of the Application
                or the Services without notice if you breach any provision of
                the Terms or any applicable law or if your conduct impacts
                Minimis Technologies Proprietary Limited name or reputation or
                violates the rights of those of another party
              </p>
            </li>
          </ol>
        </li>
        <li className="orderHeading">
          <h5>Indemnity</h5>
          <p className="paragraph2">
            You agree to indemnify Minimis Technologies Proprietary Limited, its
            affiliates, employees, agents, contributors, third party content
            providers and licensors from and against:
          </p>
          <ol type="a" className="orderList2">
            <li className="orderHeading2">
              <p className="paragraph1">
                all actions, suits, claims, demands, liabilities, costs,
                expenses, loss and damage (including legal fees on a full
                indemnity basis) incurred, suffered or arising out of or in
                connection with your content;
              </p>
            </li>
            <li className="orderHeading2">
              <p className="paragraph1">
                any direct or indirect consequences of you accessing, using or
                transacting on the Application or attempts to do so;
              </p>
            </li>
            <li className="orderHeading2">
              <p className="paragraph1">any breach of the Terms.</p>
            </li>
          </ol>
        </li>
        <li className="orderHeading">
          <h5>Dispute Resolution</h5>
          <ol className="ps-5 custom-counter">
            <li className="orderHeading">
              <h5>
                14.1 <span className="ms-4">Compulsory:</span>
              </h5>
              <p className="paragraph3">
                If a dispute arises out of or relates to the Terms, either party
                may not commence any Tribunal or Court proceedings in relation
                to the dispute, unless the following clauses have been complied
                with (except where urgent interlocutory relief is sought).
              </p>
            </li>
            <li className="orderHeading">
              <h5>
                14.2 <span className="ms-4">Notice:</span>
              </h5>
              <p className="paragraph3">
                A party to the Terms claiming a dispute (<b>Dispute</b>) has
                arisen under the Terms, must give written notice to the other
                party detailing the nature of the dispute, the desired outcome
                and the action required to settle the Dispute.
              </p>
            </li>
            <li className="orderHeading">
              <h5>
                14.3 <span className="ms-4">Resolution:</span>
              </h5>
              <p className="paragraph3">
                On receipt of that notice (<b>Notice</b>) by that other party,
                the parties to the Terms (<b>Parties</b>) must:
              </p>
              <ol type="a" className="orderList2">
                <li className="orderHeading2">
                  <p className="paragraph1">
                    Within 28 days of the Notice endeavour in good faith to
                    resolve the Dispute expeditiously by negotiation or such
                    other means upon which they may mutually agree;
                  </p>
                </li>
                <li className="orderHeading2">
                  <p className="paragraph1">
                    If for any reason whatsoever, 28 days after the date of the
                    Notice, the Dispute has not been resolved, the Parties must
                    either agree upon selection of a mediator or request that an
                    appropriate mediator be appointed by the Australian
                    Mediation Association;
                  </p>
                </li>
                <li className="orderHeading2">
                  <p className="paragraph1">
                    The Parties are equally liable for the fees and reasonable
                    expenses of a mediator and the cost of the venue of the
                    mediation and without limiting the foregoing undertake to
                    pay any amounts requested by the mediator as a precondition
                    to the mediation commencing. The Parties must each pay their
                    own costs associated with the mediation;
                  </p>
                </li>
                <li className="orderHeading2">
                  <p className="paragraph1">
                    The mediation will be held in Sydney, Australia.
                  </p>
                </li>
              </ol>
            </li>
            <li className="orderHeading">
              <h5>
                14.4 <span className="ms-4">Confidential:</span>
              </h5>
              <p className="paragraph3">
                All communications concerning negotiations made by the Parties
                arising out of and in connection with this dispute resolution
                clause are confidential and to the extent possible, must be
                treated as "without prejudice" negotiations for the purpose of
                applicable laws of evidence
              </p>
            </li>
            <li className="orderHeading">
              <h5>
                14.5 <span className="ms-4">Termination of Mediation:</span>
              </h5>
              <p className="paragraph3">
                If 2 months have elapsed after the start of a mediation of the
                Dispute and the Dispute has not been resolved, either Party may
                ask the mediator to terminate the mediation and the mediator
                must do so.
              </p>
            </li>
          </ol>
        </li>
        <li className="orderHeading">
          <h5>Venue and Jurisdiction</h5>
          <p className="paragraph2">
            The Services offered by Minimis Technologies Proprietary Limited is
            intended to be viewed by residents of Australia. In the event of any
            dispute arising out of or in relation to the Application, you agree
            that the exclusive venue for resolving any dispute shall be in the
            courts of New South Wales, Australia
          </p>
        </li>
        <li className="orderHeading">
          <h5>Governing Law</h5>
          <p className="paragraph2">
            The Terms are governed by the laws of New South Wales, Australia.
            Any dispute, controversy, proceeding or claim of whatever nature
            arising out of or in any way relating to the Terms and the rights
            created hereby shall be governed, interpreted and construed by,
            under and pursuant to the laws of New South Wales, Australia,
            without reference to conflict of law principles,\n notwithstanding
            mandatory rules. The validity of this governing law clause is not
            contested. The Terms shall be binding to the benefit of the parties
            hereto and their successors and assigns
          </p>
        </li>
        <li className="orderHeading">
          <h5>Severance</h5>
          <p className="paragraph2">
            If any part of these Terms is found to be void or unenforceable by a
            Court of competent jurisdiction, that part shall be severed and the
            rest of the Terms shall remain in force.
          </p>
        </li>
      </ol>
    </div>
  );
};

export default TermsOfServicesPage;
